
export const getAssociateName = (type, associate) => {

  if (associate){
    if (type === 'Contact'){
        if (associate.first_name == null && associate.last_name == null)
            return associate.email;
        else {
            if (associate.first_name == null)
                return associate.last_name;
            else if (associate.last_name == null)
                return associate.first_name;
            else
                return associate.first_name + ' ' + associate.last_name;
        }
    }else
        return associate.name;

  }else
    return '';
}

export const formatABN = (value) => {
    return value ? value.replace(/\s/g,'') : null; 
} 

export const formatAmount = (value) => {
    return (value && !isNaN(value)) ? +value : null;
} 

export const getContactName = (contact) => {
    if (contact.first_name == null && contact.last_name == null){
        return contact.email;
    }else {
        if (contact.first_name == null){
            return contact.last_name;
        }else if (contact.last_name == null){
            return contact.first_name;
        }else{
            return contact.first_name + ' ' + contact.last_name;
        }
    }
}

export const getContactSearchName = (contact) => {
    if (contact.first_name == null && contact.last_name == null){
        return contact.email;
    }else {
        if (contact.first_name == null){
            return contact.last_name + ' (' + contact.email + ')';
        }else if (contact.last_name == null){
            return contact.first_name + ' (' + contact.email + ')';
        }else{
            return contact.first_name + ' ' + contact.last_name + ' (' + contact.email + ')';
        }
    }
}

export const CheckUserPermissionExists = (permissionToCheck) => {
    // console.log("CheckUserPermissionExists called for " + permissionToCheck);
    const permissionsString  = localStorage.getItem('loggedUserPermissions');
    var UserPermissions= JSON.parse(permissionsString);
    const userRolesString  = localStorage.getItem('loggedUserRoles');
    var UserRoles = JSON.parse(userRolesString);

    var permissionExists = false;

    if(UserRoles){
        for (const role of UserRoles){
            if(role.id == 1){
                console.log("User is super admin");
                permissionExists = true;
                return permissionExists;
            }
        }
    }

    if(UserPermissions){
        for (const permission of UserPermissions){
            if(permission.name == permissionToCheck){
                // console.log(permissionToCheck + " exists for user.");
                permissionExists = true;
                break;
            }
        };
    }
    return permissionExists;
}

export const isFxPortfolio = (portfolio_id) => {
    return (portfolio_id == 10);
}

export const isDiscretionaryPortfolio = (portfolio_id) => {
    return (portfolio_id == 31 || portfolio_id == 32);
}

export const isGrowthPortfolio = (portfolio_id) => {
    return (portfolio_id == 3 || portfolio_id == 4 || portfolio_id == 24);
}